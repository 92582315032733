import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { MovementApproval, SearchMovement } from '../../../models/movement-approval';
import { MovementApprovalService } from '../../../services/movement-approvalService';
import { Router } from '@angular/router';
import { Globle, ErrorCode } from '../../../Globle';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'ngx-approve-movement',
  templateUrl: './approve-movement.component.html',
  styleUrls: ['./approve-movement.component.scss']
})



export class ApproveMovementComponent implements OnInit , OnDestroy,AfterViewInit {

  dtOptions:  DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  varl: string[] = null;

  movementApproval1 : MovementApproval = new MovementApproval()
  movementApproval :SearchMovement = new SearchMovement()


  movementApprovalArray = [];
  static loginArr=[];
  flag : boolean = true;
  companyId

  constructor(private movementSearchSearvice : MovementApprovalService,private router: Router) { }
  
  
  
  
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnInit() {
    this.dtOptions = {  
      pageLength: 10,
      ordering: true,
      searching: true,
    };
    // (<HTMLInputElement>document.getElementById("companyId")).value = '102';//myPayload[0].companyId;
    //   this.checkOnLoginByDesg(102,6002);//myPayload[0].companyId
    if (JSON.parse(sessionStorage.getItem('payload')) != null) {
      let myPayload = JSON.parse(sessionStorage.getItem('payload'));
      this.companyId = myPayload[0].companyId;
      //(<HTMLInputElement>document.getElementById("companyId")).value = myPayload[0].companyId;
      this.checkOnLoginByDesg(myPayload[0].companyId,myPayload[0].employeeId);
    }
    if(ApproveMovementComponent.loginArr.length>0){
      this.movementApprovalArray = ApproveMovementComponent.loginArr;
      Globle.movmentApproval = ApproveMovementComponent.loginArr
      
      this.Remove();
    }
   
  }

  Remove() {
    (<HTMLInputElement>document.getElementById("searchval")).remove()
  }

  Search() {
    var companyId = parseInt((<HTMLInputElement>document.getElementById("companyId")).value)
    var childAssetNo = (<HTMLInputElement>document.getElementById("childAssetNo")).value
    //var parentAssetNo = (<HTMLInputElement>document.getElementById("parentAssetNo")).value
    var approverStatus = (<HTMLInputElement>document.getElementById("approverStatus")).value
    var serialNo = (<HTMLInputElement>document.getElementById("serialNo")).value
    var tagNo = (<HTMLInputElement>document.getElementById("tagNo")).value

    this.movementApproval.companyId=companyId;
    this.movementApproval.childAssetNo=childAssetNo;
    //this.movementApproval.parentAssetNo = parentAssetNo;
    this.movementApproval.approverStatus = approverStatus;
    this.movementApproval.serialNo= serialNo;
    this.movementApproval.tagNo = tagNo;
    this.movementApproval.department = (<HTMLInputElement>document.getElementById("department")).value;
    this.movementApproval.movementType = (<HTMLInputElement>document.getElementById("movementType")).value;
    this.movementApproval.acknowledgedBy = (<HTMLInputElement>document.getElementById("acknowledgeBy")).value;
    this.movementApproval.acknowledgedByRecipent = (<HTMLInputElement>document.getElementById("acknowledgeByRecipent")).value;
    this.movementApproval.expectedReturnDate = (<HTMLInputElement>document.getElementById("expectedReturnDate")).value;
    this.movementApproval.employeeId = parseInt((<HTMLInputElement>document.getElementById("employeeId")).value);
/*
    var searchMap = new Map();
    searchMap.set("childAssetNo", childAssetNo);
    searchMap.set("parentAssetNo", parentAssetNo);
    searchMap.set("approverStatus",approverStatus)
    searchMap.set("serialNo", serialNo);
    searchMap.set("tagNo", tagNo);
    //console.log(searchMap.keys);
    //console.log(searchMap.toString());
    var val1 = JSON.stringify(Array.from(searchMap.entries()));
    // var res = val1.replace(/[/gi, "red");
    var v1= val1.replace(/\]/g, '');
    var v2 = v1.replace(/\[/g, '');
    //console.log("val1 : "+val1);
    //console.log("data : "+v2);
    // var val: string = "asdf";
  */ 
  if (this.movementApproval != null) {
      this.movementSearchSearvice.listMovementSearch(this.movementApproval).subscribe(
        (results) => {
          this.movementApprovalArray = results;
          Globle.movmentApproval = this.movementApprovalArray;
          //console.log(this.movementApprovalArray);
          if(this.movementApprovalArray.length>0){ 
            //console.log("In IF");
            //this.rerender();
            this.Remove();}
            else{
              //console.log("IN ELSE");
              alert("Data Not Found")
              this.clear();
            }
        })
      

    }
    
   
  }

//unChekedArray=[]
checkedMap = new Set
  Check(invId) {
    Globle.movementId = invId;
    //console.log(invId);
    this.fillArrayForTasks(invId);
    //this.mapTest(invId);
    this.checkedMap.add(invId);
    //this.unChekedArray.push(invId);
    ////console.log("checkedMap Array ",this.checkedMap)
    ////console.log("unchecked Array ",this.unChekedArray)
  }
  finalArray=new Map();
  set = new Set();
  private fillArrayForTasks(invId){
    if(Globle.movmentApproval.length>0 ){
      for(var i=0;i<Globle.movmentApproval.length;i++){
        if(invId == Globle.movmentApproval[i].id){
          if(this.finalArray.has(invId)){
            this.finalArray.delete(invId);
          }else{
          this.finalArray.set(invId,Globle.movmentApproval[i]);
          }
          if(invId == Globle.movmentApproval[i].id){
            if(this.set.has(invId)){
              this.set.delete(invId);
            }else{
            this.set.add(invId)
            }
          }
        }
        //console.log("finalarray ",this.finalArray)
        //console.log("set ",this.set)
      }
    }

    // var list =this.finalArray.values();
    // //console.log("LIST ==> ",list)
  }
  private mapTest(invId){
    let map = new Map<object, string>(); 
    if(Globle.movmentApproval.length>0 ){
      for(var i=0;i<Globle.movmentApproval.length;i++){
        if(invId == Globle.movmentApproval[i].id){
          if(map.has(invId)){
            map.delete(invId);
          }else{
            map.set(invId,Globle.movmentApproval[i]);
          }
        }
    }
    //console.log("Test Map ",map)
  }
}

  Open() {
    //console.log(" this.designation.empDesignation ", this.designation.empDesignation)
    if(this.designation.empDesignation == "Transporter" && Globle.movementId >0){
      //console.log("in if")
      this.router.navigate(['/pages/movement-maintenance/transport-movement']);
      //{ { this.movementApprovalArray } }
    }
    
    else if(Globle.movmentApproval.length>0 ){
      if(this.designation.empDesignation == "Manager" || this.designation.empDesignation == "Employee"){
        //console.log("196")
      this.router.navigate(['/pages/movement-maintenance/movement']);
    //{ { this.movementApprovalArray } }
    }
    else if(this.designation.empDesignation == "Security" ){
      //console.log("201")
      this.router.navigate(['/pages/movement-maintenance/security-movement']);//movement-security
    }
    /*else if((this.designation == "Transporter" )){
      this.router.navigate(['/movement-security']);
    }*/
    }else{
      alert("Please Search First !!")
    }
  }
  rejectArr=[];
  approve(){
//     if(this.finalArray.size > 0){
//       //console.log("this.finalArray ==> ",this.finalArray)
//      var list = [];

//       for(var k=0; k<this.set.size;k++){
//         //console.log(this.set[k])
//         list.push(this.finalArray.get(this.set[k]))
//       }
      
//  //console.log("list ",list)
//       //this.movementSearchSearvice.approvedallRecord(this.finalArray).subscribe(
//        // data => //console.log(data),error =>console.error(error)
//         //ErrorCode.getErrorCode(data),error => ErrorCode.getErrorCode(error)
//        // )
//     }
// for (let entry of this.set) {
//   //console.log(entry);
// }
    var List1=[]
    //console.log("this.set ==>",this.set)
    if(Globle.movmentApproval.length>0){
      for (let entry of this.set) {
        //console.log("this.set[k] ==>",this.set)
        for(var j=0;j<Globle.movmentApproval.length;j++){
          if(entry==Globle.movmentApproval[j].id){
            List1.push(Globle.movmentApproval[j])
          }
        }
        //console.log("LIST ",List1)
      }
    }
  }
  approvedAll(){
    if(Globle.movmentApproval.length>0){
      var approveArraay = [];
      var approvedArr = Globle.movmentApproval
      //console.log("approved arr Before==> ",approvedArr);
      for(var i=0;i<approvedArr.length;i++){
        if(approvedArr[i].approverStatus == "Submitted"){
          approvedArr[i].approverStatus = "Approved";
          approveArraay.push(approvedArr[i]);
        }
      }
    
    //console.log("approve arr after ==> ",approveArraay);
   
      // for(var k=0;k<this.unChekedArray.length;k++){
      //   for(var i=0;i<approveArraay.length;i++){
      //     if(this.unChekedArray[k] == approveArraay[i].id){
      //       approveArraay.splice(i,1);
      //     }
      //   }
        
      // }
      var list1=[]
      for(let entry of this.set){
        for(var j=0;j<approveArraay.length;j++){
          if(entry==approveArraay[j].id){
            list1.push(approveArraay[j])
          }
        }
      }
      //console.log("list1 arr after ==> ",list1);
      this.movementSearchSearvice.approvedallRecord(list1).subscribe(
        data => ErrorCode.getErrorCode(data),error => ErrorCode.getErrorCode(error)
        )
        //this.router.navigate(['/movement-approval']);
    }
  }
  rejectAll(){
    if(Globle.movmentApproval.length>0){
     // var rejectArraay = [];
      var rejectArr = Globle.movmentApproval
      //console.log("rejectArr arr Before==> ",rejectArr);
      for(var i=0;i<rejectArr.length;i++){
        if(rejectArr[i].approverStatus == "Submitted"){
          rejectArr[i].approverStatus = "Reject";
          //rejectArraay.push(rejectArr[i]);
        }
      }
    
   
      // for(var k=0;k<this.unChekedArray.length;k++){
      //   for(var i=0;i<rejectArr.length;i++){
      //     if(this.unChekedArray[k] == rejectArr[i].id){
      //      //rejectArraay.splice(i,1);
      //      rejectArraay.push(rejectArr[i]);
      //       //console.log("rejectArraay arr after ==> ",rejectArraay);
      //     }
      //   }
      // }
      var list1=[]
      for(let entry of this.set){
        for(var j=0;j<rejectArr.length;j++){
          if(entry==rejectArr[j].id){
            list1.push(rejectArr[j])
          }
        }
      }
      this.movementSearchSearvice.rejectAllRecord(list1).subscribe(
        data => ErrorCode.getErrorCode(data),error => ErrorCode.getErrorCode(error)
      )
      //this.router.navigate(['/movement-approval']);
    }
  }
  sendReminder(){
    if(Globle.movmentApproval.length>0){
      var reminderArr = Globle.movmentApproval;
      var list1=[]
      for(let entry of this.set){
        for(var j=0;j<reminderArr.length;j++){
          if(entry==reminderArr[j].id){
            list1.push(reminderArr[j])
          }
        }
      }
      //console.log("LIST! ",list1)
      this.movementSearchSearvice.sendReminder(list1).subscribe(
        data => ErrorCode.getErrorCode(data),error => ErrorCode.getErrorCode(error)
      )
      this.router.navigate(['/pages/movement-maintenance/ngx-approve-movement']);
    }
  }
 designation  ;
  checkOnLoginByDesg(companyId,employeeId){
    this.movementSearchSearvice.checkOnLoginByDesg(companyId,employeeId).subscribe(
      data =>{
        this.designation = data
        //console.log("in checkOnLogin movementArr ",this.designation)
        if(this.designation !=null && this.designation.empDesignation =="Transporter"){
          this.flag =  false;
          (<HTMLInputElement>document.getElementById("approverStatus")).value="Shipped";
          (<HTMLInputElement>document.getElementById("approverStatus")).disabled=true;
          // (<HTMLInputElement>document.getElementById("approve")).disabled=true;
          // (<HTMLInputElement>document.getElementById("reject")).disabled=true;s
          // (<HTMLInputElement>document.getElementById("reminder")).disabled=true;
         }
         else if(this.designation !=null && this.designation.empDesignation =="Security"){
          (<HTMLInputElement>document.getElementById("approverStatus")).value="Approved";
          // (<HTMLInputElement>document.getElementById("approverStatus")).disabled=true;
          // (<HTMLInputElement>document.getElementById("approve")).disabled=true;
          // (<HTMLInputElement>document.getElementById("reject")).disabled=true;
          // (<HTMLInputElement>document.getElementById("reminder")).disabled=true;
         }
      }
    )
    //return this.designation;
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  clear(){
    (<HTMLInputElement>document.getElementById("childAssetNo")).value='';
    //(<HTMLInputElement>document.getElementById("parentAssetNo")).value='';
    (<HTMLInputElement>document.getElementById("serialNo")).value='';
    (<HTMLInputElement>document.getElementById("tagNo")).value='';
  }
   

}