<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <!-- <a class="logo" href="#" (click)="navigateHome()"><mark style="color:red ;background:none">PowerERP</mark></a>width="150px" height="50px" -->
    <a class="logo" href="#" (click)="navigateHome()"><img src="../../../../assets/images/LOGO Ledger 24.png" alt="Back" class = "img"/></a>
    <a class="logo" (click)="backClicked()" [hidden]="isMobile"><img src="../../../../assets/images/back.png" alt="Back" width="30px;" height="25px" class = "imgBack"/></a>
    <a (click)="goToHome()" class="homeClass"><img src="../../../../assets/images/home.png" alt="Back" width="30px;" height="25px" class = "imgBack"/></a>
   
    <!-- <a class="logo"  (click)="navigateToTms()"  >Go To Tms</a> -->
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
 
</div>

<div class="header-container">
  <ngx-search-input></ngx-search-input>
  <nb-actions size="small">

    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action> -->

   

    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <!-- <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.picture">
      </nb-user> -->
      <!-- width="150px" height="50px" -->
    <img [src]="img" class ="companyLogo" *ngIf="img" width="100px" height="75px"/>
    
    &nbsp;&nbsp;&nbsp;&nbsp;
      <nb-user [size]="medium"
               [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="name"
               [showName]="!isMobile">
             
      </nb-user>
      <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img [src]="img" width="50px" height="50px" *ngIf="img"/> -->
             
    </nb-action>
  </nb-actions>
  
</div>

