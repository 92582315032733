import { HttpClient, HttpHeaders } from "@angular/common/http";
import 'rxjs/add/operator/toPromise';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Userlogin } from "../models/userlogin";
import { environment } from "../../environments/environment";
import { CompanyBrnch } from "../models/companybrnch";
import { Employees } from "../models/employees";
import { Department } from "../models/department";
import { Role } from "../models/role";


@Injectable(
  {
    providedIn: 'root'
  }
)
export class UserloginService
{
  constructor(private http: HttpClient) { }

  private reqHeader = new HttpHeaders({
    "Access-Control-Allow-Origin":"*",
    "Access-Control-Allow-Methods":"GET, POST",
    "Content-Type":"application/json",
});

  //private baseUrl = 'http://localhost:7072/api/userlogin';
  //private baseUrl = 'http://192.168.0.101:7072/api/userlogin';
  private baseUrl = `${environment.apiUrl}/fams/userlogin`;

  createUserlogin (userlogin: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}` + `/create`, userlogin,{headers: this.reqHeader,observe : 'response'});
  }
  updateUserlogin(userlogin: Object): Observable<Object> {
    return this.http.put(`${this.baseUrl}` + `/update`, userlogin,{headers: this.reqHeader,observe : 'response'});
  }
  allCompany(id: number): Observable<Object> {
    return this.http.get(`${this.baseUrl}/${id}`);
  }
  getBranchByCompanyCode(companyCode){
  return this.http.get<CompanyBrnch[]>(`${environment.apiUrl}/fams/companybranch/findCompany/${companyCode}`,{headers: this.reqHeader});
  }
  getEmployeeByCompanyCode(companyCode){
    return this.http.get<Employees[]>(`${environment.apiUrl}/fams/employee/findAll/${companyCode}`,{headers: this.reqHeader,})
  }
  listDepartment(companyId) {
  return this.http.get<Department[]>(`${environment.apiUrl}/fams/department/findall/${companyId}`,{headers: this.reqHeader});
  }
  listRole(companyId)
  {
  return this.http.get<Role[]>(`${environment.apiUrl}/fams/role/findAll/${companyId}`,{headers: this.reqHeader}) ;
  }
  getLoginByCompanyCode(companyCode){
    return this.http.get<Userlogin[]>(`${environment.apiUrl}/fams/userlogin/findAll/${companyCode}`,{headers: this.reqHeader});
  }
  // searchLogin(companyCode,branchId,empCode){
  //   return this.http.get(`${environment.apiUrl}/fams/userlogin/searchLoginWithJoin/${companyCode}/${branchId}/${empCode}`);
  // }
  searchLogin(companyCode,empCode){
    return this.http.get(`${environment.apiUrl}/fams/userlogin/searchLoginWithJoin/${companyCode}/${empCode}`,{headers: this.reqHeader});
  }
  checkEmployeeCode(companyCode,empCode){
    return this.http.get(`${environment.apiUrl}/fams/employeeData/checkEmployee/${companyCode}/${empCode}`,{headers: this.reqHeader});
  }
  checkNoOfUsers(companyCode){
    return this.http.get<Boolean>(`${environment.apiUrl}/fams/userlogin/checkNoOfUsers/${companyCode}`,{headers: this.reqHeader});
  }
  searchEmpCode(companyCode,empCode){
    return this.http.get(`${environment.apiUrl}/fams/userlogin/searchEmpCode/${companyCode}/${empCode}`,{headers: this.reqHeader});
  }
  checkLogin(companyCode,empCode){
    return this.http.get(`${environment.apiUrl}/fams/userlogin/searchExistingLogin/${companyCode}/${empCode}`,{headers: this.reqHeader});
  }
  userLogOut(loginId,id) {
    return this.http.get(`${environment.apiUrl}/fams/userlogin/logout/` + loginId+'/'+id ).pipe()
  }


  getLogedInUsers(companyId) {
    return this.http.get<Userlogin[]>(`${environment.apiUrl}/fams/userlogin/findlogedin/${companyId}`);
    }
}