export class ReportFilter {
    companyId: any
    branchId
    empCode
    glName
    documentType = ""
    periodFrom = ""
    periodTo = ""
    fiscalYear = ""
    costCenter
    processingStage
    file: FileReader;
    startDate: string;
    endDate: string;
    getAll: boolean;
    role
    data: any;
    allBranch: boolean;
    allCostCenter: boolean;
    userRole;
    invoiceIdArr = [];
    vendorName: string;
    ageingOn: string;
    currentDate: string;
    reportId: Number;
    customerName: string;
    custId: Number;
    msme: Number;
    glCode: any;
    paymentVoucherNo;
    voucherFrom: number;
    voucherTo: number;
    vendorFlag;
    coucherFlag;
    mode;
    voucherFlag;
    voucherfrom;
    voucherto
    vendorCode;
    paymentType;
    accountNo;
    tdsFlag;
    salesInvoiceMap;
    invoiceList = [];
    regime;
    glgroup;
    glClass;
    costCenterD;
    documentId;
    vendorType;
    criteria;
    bankName;
    valueIn;
    salesPerson;
    gstNo;
};