import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-search-input',
  styleUrls: ['./search-input.component.scss'],
  template: `
   
       <a (click)="showInput()" class="searchImage"><img src="../../../../assets/images/search (1).png" alt="Search" class = "searchImg"/></a>
    
    <input placeholder="Type your search request here..."
           #input
           [class.hidden]="!isInputShown"
           (blur)="hideInput()"
           
           (keyup.enter)="onInput(input.value)">
  `,
})
// (input)="onInput($event)"
// <i class="control-icon ion ion-ios-search"
// (click)="showInput()"></i>
export class SearchInputComponent {


  constructor(private route:Router){}

  roleId
  ngOnInit(): void {

    if (JSON.parse(sessionStorage.getItem('payload')) != null) {
      let myPayload = JSON.parse(sessionStorage.getItem('payload'));
      // console.log(myPayload);


      this.roleId = myPayload[0].roleId;

    }
  }


  @ViewChild('input', { static: true }) input: ElementRef;

  @Output() search: EventEmitter<string> = new EventEmitter<string>();

  isInputShown = false;

  showInput() {
    this.isInputShown = true;
    this.input.nativeElement.focus();
  }

  hideInput() {
    this.isInputShown = false;
  }

  onInput(val: string) {
    this.search.emit(val);

    // if (this.roleId === 5002 || this.roleId === 5001) {
      switch (val) {

        case "SI21": {
          this.route.navigate(['/pages/bank-payment/sales-invoice']);
          break;
        }
        case "SI23": {
          
          this.route.navigate(['/pages/bank-payment/search-sales-invoice']);
          break;
        }
        case "EI21": {
          this.route.navigate(['/pages/apreport/e-invoice']);
          break;
        }
        case "EW21": {
          this.route.navigate(['/pages/bank-payment/e-way-bill']);
          break;
        }

        case "PI21": {
          this.route.navigate(['/pages/accounts-payable/book']);
          break;
        }
        case "PI23": {
          
          this.route.navigate(['/pages/accounts-payable/validate']);
          break;
        }
        case "PO21": {
          this.route.navigate(['/pages/inventory-model/purchase-order']);
          break;
        }
        case "PO23": {
          this.route.navigate(['/pages/inventory-model/view-po-status']);
          break;
        }

        case "VM21": {
          this.route.navigate(['/pages/settings/vendors']);
          break;
        }
        case "CM21": {
          this.route.navigate(['/pages/settings/customers']);
          break;
        }

        case "BP21": {
          this.route.navigate(['/pages/accounts-payable/bank-payment']);
          break;
        }

        case "VA21": {
          this.route.navigate(['/pages/accounts-payable/vendor-advance-payment']);
          break;
        }
        case "BP23": {
          
          this.route.navigate(['/pages/accounts-payable/search-paid-invoices']);
          break;
        }
        case "PR21": {
          this.route.navigate(['/pages/inventory-model/p-requisition']);
          break;
        }
        case "PR23": {
          this.route.navigate(['/pages/inventory-model/pr-status']);
          break;
        }

        case "IM21": {
          this.route.navigate(['/pages/settings/item-master']);
          break;
        }
        case "CR21": {
          this.route.navigate(['/pages/bank-payment/receipt-bank/']);
          break;
        }
        case "CR23": {
          this.route.navigate(['/pages/bank-payment/search-paid-receipt']);
          break;
        }
        case "CA21": {
          this.route.navigate(['/pages/bank-payment/customer-advance']);
          break;
        }

        case "DB23": {
          this.route.navigate(['/pages/generalledger/daybook-summary']);
          break;
        }
        case "DB22": {
          this.route.navigate(['/pages/generalledger/daybook']);
          break;
        }
        case "JV21": {
          this.route.navigate(['/pages/accounts-payable/upload-vendorjv']);
          break;
        }

        default: {

        }

      }
    // }else iF9

  }
}
