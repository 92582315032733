import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { CompanyService } from '../../../services/companyService';
import { DomSanitizer } from '@angular/platform-browser';
import { UserloginService } from '../../../services/userloginService';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
// export class HeaderComponent implements OnInit, OnDestroy {

//   private destroy$: Subject<void> = new Subject<void>();
//   userPictureOnly: boolean = false;
//   user: any;

//   themes = [
//     {
//       value: 'default',
//       name: 'Light',
//     },
//     {
//       value: 'dark',
//       name: 'Dark',
//     },
//     {
//       value: 'cosmic',
//       name: 'Cosmic',
//     },
//     {
//       value: 'corporate',
//       name: 'Corporate',
//     },
//   ];

//   currentTheme = 'default';

//   userMenu = [ { title: 'Profile' }, { title: 'Log out' } ];

//   constructor(private sidebarService: NbSidebarService,
//               private menuService: NbMenuService,
//               private themeService: NbThemeService,
//               private userService: UserData,
//               private layoutService: LayoutService,
//               private breakpointService: NbMediaBreakpointsService) {
//   }

//   ngOnInit() {
//     this.currentTheme = this.themeService.currentTheme;

//     this.userService.getUsers()
//       .pipe(takeUntil(this.destroy$))
//       .subscribe((users: any) => this.user = users.nick);

//     const { xl } = this.breakpointService.getBreakpointsMap();
//     this.themeService.onMediaQueryChange()
//       .pipe(
//         map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
//         takeUntil(this.destroy$),
//       )
//       .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

//     this.themeService.onThemeChange()
//       .pipe(
//         map(({ name }) => name),
//         takeUntil(this.destroy$),
//       )
//       .subscribe(themeName => this.currentTheme = themeName);
//   }

//   ngOnDestroy() {
//     this.destroy$.next();
//     this.destroy$.complete();
//   }

//   changeTheme(themeName: string) {
//     this.themeService.changeTheme(themeName);
//   }

//   toggleSidebar(): boolean {
//     this.sidebarService.toggle(true, 'menu-sidebar');
//     this.layoutService.changeLayoutSize();

//     return false;
//   }

//   navigateHome() {
//     this.menuService.navigateHome();
//     return false;
//   }
// }

export class HeaderComponent implements OnInit, OnDestroy {

  currentTheme = 'default';
  logout: any;
  userPicture = "../../../../assets/images/no-user.png";
  userMenu = [{ title: 'Logout' }];

  constructor(private menuService: NbMenuService,
    private sidebarService: NbSidebarService,
    private layoutService: LayoutService, private userloginService: UserloginService,
    private themeService: NbThemeService,
    private route: Router, private _location: Location,
    private companyService: CompanyService, private sanitizer: DomSanitizer) {


  }
  companyCode = ""
  name = ""
  loginId
  id
  roleIde

  isMobile: boolean;
  size = "medium";

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.isMobile = window.innerWidth <= 768; // Adjust the threshold based on your design
    // You can perform additional actions based on the screen size here
    this.size = this.isMobile ? 'tiny' : 'medium';
  //console.log(" is mobile ", this.isMobile, event);


  }


  ngOnInit() {

    this.currentTheme = this.themeService.currentTheme;
    //this.userName = sessionStorage.getItem('userName');
    this.logout = this.menuService.onItemClick()
      .subscribe((event) => {
        if (event.item.title === 'Logout') {
          sessionStorage.clear();
          this.userLogout();
          // //console.log("logout");
          this.route.navigate(['/login']);
        }
      });


    let myPayload = JSON.parse(sessionStorage.getItem('payload'));
    if (myPayload != null) {
      this.companyCode = myPayload[0].companyId;
      this.loginId = myPayload[0].loginId;
      this.id = myPayload[0]
      this.roleIde = myPayload[0].roleId;
      /*****************add on 18-02-2023************* */
      this.name = myPayload[0].loginId;
      //  //console.log("payload ",myPayload);

      //  if (myPayload[0].roleId == 1004) {
      //   this.name ="Leader"; 
      // }
      // if (myPayload[0].roleId == 1005) {
      //   this.name ="Checker"; 
      // }
      // if (myPayload[0].roleId == 1006) {
      //   this.name ="Maker"; 
      // }
      // if (myPayload[0].roleId == 1001) {
      //   this.name ="Developer"; 
      // }

      /********************************************** */

      this.fetchCompany();
      this.changeToMobile();
    }
  }


  
  changeToMobile(){
    this.isMobile = window.innerWidth <= 768; // Adjust the threshold based on your design
    // this.size = this.isMobile ? 'tiny' : 'medium';

    if(this.isMobile){
      // this.size = 'tiny';
      this.userMenu = [{ title: this.name } ,{ title: 'Logout' }];
    }else{
      // this.size= 'medium';
     this.userMenu = [ { title: 'Logout' }];
    }
   
  }

  ngOnDestroy() {
    this.logout.unsubscribe();
    // Unsubscribe from the window resize event when the component is destroyed
    window.removeEventListener('resize', this.onResize);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }
  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
  navigateToTms() {
    window.open("http://tms.mymonthlycharge.com")
  }
  backClicked() {
    this._location.back();
  }

  resp
  userLogout() {
    this.userloginService.userLogOut(this.loginId, this.id).subscribe(response => {
      this.resp = response;
    })
  }

  img
  fetchCompany() {
    this.companyService.searchCompany(this.companyCode).subscribe(
      data => {
        //  //console.log(data);
        var companyLogo = data
        // //console.log("logo ",companyLogo);

        // //console.log(companyLogo);

        let objectURL = 'data:image/jpeg;base64,' + companyLogo[0].image;

        this.img = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        //  //console.log(this.img);

      }
    )
  }

  goToHome() {
    // this.route.navigate(['/pages/welcome-page']);
    if (this.roleIde == 1004 || this.roleIde == 1005 || this.roleIde == 1008) {
      this.route.navigate(['/pages/welcome']);
    } else {
      this.route.navigate(['/pages/welcome-login']);
    }
  }

}
