import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Login } from "../models/login";
import { environment } from '../../environments/environment';
import { Company } from "../models/company";
import { Movement } from "../models/movement";
import { Observable } from 'rxjs';

@Injectable(
  {
    providedIn: 'root'
  }
)

export class LoginService {
  constructor(private http: HttpClient) { }
  userLogin(companyId, userId, pwd) {
    return this.http.get<Login[]>(`${environment.apiUrl}/fams/userlogin/findlogin/` + companyId + `/` + userId + `/` + pwd).pipe(map(res => res))
  }

  searchCompanyId(term) {
    return this.http.get<Company[]>(`${environment.apiUrl}/fams/company/find12/` + term +'/true' ).pipe(map(res => res))
  }

  searchLoginId(term) {
    return this.http.get<Login[]>(`${environment.apiUrl}/fams/userlogin/finduser/` + term ).pipe(map(res => res))
  }
  checkOnLogin(companyId,employeeId){
    return this.http.get<Movement[]>(`${environment.apiUrl}/fams/movement/managerLogin/` + companyId+"/" +employeeId).pipe(map(res => res))
  }
  checkOnLoginByDesg(companyId,employeeId){
    return this.http.get(`${environment.apiUrl}/fams/movement/managerLogin/` + companyId+"/" +employeeId).pipe(map(res => res))
  }
  getForms(reportFilter: Object): Observable<Object> {
    var obj=this.http.post(`${environment.apiUrl}/fams/formauthentication/getforms`, reportFilter);
        return obj;
  }
  
}


